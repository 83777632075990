import moment from 'moment';

jQuery( document ).ready(function($) {
    $('#main').fadeIn();
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Khai Dreams/events?app_id=45PRESS_khaid',
        // url: 'https://rest.bandsintown.com/artists/Kane Brown/events?app_id=45PRESS_khaid',
        method: 'GET', 
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            console.log(data);
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    
                    const event_location = event.venue.region ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    if (event.offers.length){
                        if (event.offers[0].type == "Sold Out"){
                            html += '<div class="event soldout" data-bit-id="' + event.id + '"><a href="' + event.offers[0].url + '" target="_blank">';
                        } else {
                            html += '<div class="event" data-bit-id="' + event.id + '"><a href="' + event.offers[0].url + '" target="_blank">';
                        }
                    } else{
                        html += '<div class="event" data-bit-id="' + event.id + '"><a href="' + event.url + '" target="_blank" class="">';
                    }
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM Do YYYY' ) + '</div>';
                    html += '<div class="event-location">' + event_location + '</div>';
                    // html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '</a>';
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( '<div class="noevents"><img src="./dist/img/noeventsnotice.png" alt="No Current Events Scheduled"></div>' );
            }
        }
    });

    $("a[href]").on('click', function(e){
        e.preventDefault();
        if (this.href) {
            $('body').addClass('fadeOut');
            var target = this.href;
            setTimeout(function(){
                window.location = target;
            }, 1000);
        }
    });
});